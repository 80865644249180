import Hidden from '@material-ui/core/Hidden';
import PeButtonIcon from '@pe-libs/react-components/build/components/PeButtonIcon';
import Bars from '@pe-libs/react-components/build/components/PeIcons/Bars.svg';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC, useState } from 'react';
import Logo from './Logo';
import MainMenu from './MainMenu';
import messages from './messages';
import useStyles from './styles';
import { ListItemType } from './types';

type TopBarProps = {
  menuLinks: ListItemType[];
  actionComponent: FC<unknown>;
  disableLanguageSwitch?: boolean;
  logoLink?: string;
  logoAlt?: string;
};

const TopBar: FC<TopBarProps> = ({ logoLink, menuLinks, actionComponent, disableLanguageSwitch, logoAlt }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={styles.headerWrapper}>
      <header className={styles.mainHeader}>
        <Logo logoLink={logoLink} logoAlt={logoAlt} />
        <Hidden mdUp={true}>
          <PeButtonIcon
            size="large"
            className={styles.burgerButtonDark}
            onClick={() => setMenuOpen((previous) => !previous)}
            title={formatMessage(messages.hoverBurger)}
            aria-label={`${formatMessage(messages.hoverBurger)} button`}
          >
            <Bars />
          </PeButtonIcon>
        </Hidden>
        <MainMenu
          logoAlt={logoAlt}
          logoLink={logoLink}
          open={menuOpen}
          setOpen={setMenuOpen}
          menuLinks={menuLinks}
          actionComponent={actionComponent}
          disableLanguageSwitch={disableLanguageSwitch}
        />
      </header>
    </div>
  );
};

export default TopBar;
