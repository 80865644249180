import { PopoverOrigin } from '@material-ui/core/Popover';
import { isArray } from '@pe-libs/react-components';
import HelpCircleOutlined from '@pe-libs/react-components/build/components/PeIcons/HelpCircleOutlined.svg';
import PeMenu from '@pe-libs/react-components/build/components/PeMenu';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getUserLocale } from '#modules/app/selectors';
import classNames from '#utils/classNames';
import useStyles from '../../modules/auth/components/PeContentLayout/styles';
import messages from './messages';
import { LangSwitchProps } from './types';

const LanguageSwitch: FunctionComponent<LangSwitchProps> = ({ buttonElement, isHoverable }) => {
  const styles = useStyles();
  const classesNavIcon = classNames(styles.icon, styles.iconLangSwitch);
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  const triggerButton = <HelpCircleOutlined />;
  const selectedId = useSelector(getUserLocale)?.toLowerCase();
  const formatMessage = useIntlMessage();
  const router = useRouter();

  const queryLang = isArray(router?.query.lang) ? router.query.lang[0] : router?.query.lang;

  const path = router?.asPath?.replace(`/${queryLang}/`, '');

  const langOptions = [
    {
      id: 'de-de',
      title: formatMessage(messages.languageGerman),
      altTitle: 'DE',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'de-de' } }, `/de-de/${path}`);
      },
    },
    {
      id: 'es-es',
      title: formatMessage(messages.languageSpanish),
      altTitle: 'ES',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'es-es' } }, `/es-es/${path}`);
      },
    },
    {
      id: 'fr-fr',
      title: formatMessage(messages.languageFrench),
      altTitle: 'FR',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'fr-fr' } }, `/fr-fr/${path}`);
      },
    },
    {
      id: 'it-it',
      title: formatMessage(messages.languageItalian),
      altTitle: 'IT',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'it-it' } }, `/it-it/${path}`);
      },
    },
    {
      id: 'pt-pt',
      title: formatMessage(messages.languagePortuguese),
      altTitle: 'PT',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'pt-pt' } }, `/pt-pt/${path}`);
      },
    },
    {
      id: 'nl-nl',
      title: formatMessage(messages.languageDutch),
      altTitle: 'NL',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'nl-nl' } }, `/nl-nl/${path}`);
      },
    },
    {
      id: 'en-us',
      title: formatMessage(messages.languageEnglishUs),
      altTitle: 'US',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'en-us' } }, `/en-us/${path}`);
      },
    },
    {
      id: 'en-gb',
      title: formatMessage(messages.languageEnglishGb),
      altTitle: 'GB',
      onMenuClick: () => {
        router.push({ pathname: router.pathname, query: { ...router.query, lang: 'en-gb' } }, `/en-gb/${path}`);
      },
    },
  ].filter((option) => {
    switch (option.id) {
      case 'it-it': {
        return ['it-it'].includes(queryLang);
      }

      case 'fr-fr': {
        return ['fr-fr'].includes(queryLang);
      }

      case 'es-es': {
        return ['es-es'].includes(queryLang);
      }

      case 'pt-pt': {
        return ['pt-pt'].includes(queryLang);
      }

      case 'nl-nl': {
        return ['nl-nl'].includes(queryLang);
      }

      case 'de-de': {
        return ['de-de', 'en-us', 'en-gb'].includes(queryLang) || !queryLang;
      }

      default: {
        return true;
      }
    }
  });

  return (
    <div className={classesNavIcon}>
      <PeMenu
        variant="selectedMenu"
        items={langOptions}
        buttonElement={buttonElement}
        triggerBtn={triggerButton}
        anchorOrigin={anchorOrigin}
        stylesMenuButton={styles.langSwitch}
        stylesMenuButtonActive={styles.langSwitchActive}
        selectedById={selectedId}
        isHoverable={isHoverable}
        transitionDuration={300}
      />
    </div>
  );
};

export default LanguageSwitch;
